import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateMetadata } from '../actions';
import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory';

class MetadataListPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
        metadata: props.metadata
    };

    this.handleChangeMetadata = this.handleChangeMetadata.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ metadata: nextProps.metadata });
  }

  handleChangeMetadata(field, value, option_index, to_delete) {
    let new_value = value;
    if(field === 'value' && this.state.metadata.type === 'SELECT') {
      let options = this.state.metadata.value ? JSON.parse(this.state.metadata.value) : [];
      if(typeof option_index !== 'undefined' && typeof options[option_index] !== 'undefined') {
        if(to_delete) {
          options.splice(option_index, 1);
        }else{
          options[option_index] = value;
        }
      }else{
        options.push(value);
      }
      new_value = JSON.stringify(options);
    }

    this.setState({
      metadata: Object.assign({}, this.state.metadata, { [field]: new_value })
    }, function() {
      if(to_delete) {
        this.props.onUpdateMetadataList(this.props.metadata_id, 'value', null)(this.state.metadata.value);
      }
    });
  }

  render() {
    const { onUpdateMetadataList, index, onClosePopup, metadata_id } = this.props;
    const { metadata } = this.state;

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'auto', maxHeight: '100%' }} data-test-id='question-options'>
        <div className="row small-12 columns">
          <h3>Question Options</h3>
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={e => {e.preventDefault(); onClosePopup();}}>Done</a>
        </div>
        <div className="row small-12 columns">
          <table>
            <thead>
              <tr>
                <td>Options</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(metadata.value || '[]').map((o, i) =>
                <tr key={i}>
                  <td style={{ paddingTop: 0 }}>
                    <input type="text" placeholder="Option" value={o} style={{ minWidth: '100px', margin: 0 }}
                  onChange={e => this.handleChangeMetadata('value', e.target.value, i)}
                  onBlur={e => onUpdateMetadataList(metadata_id, 'value', o)(metadata.value)} />
                  </td>
                  <td>
                    <a onClick={e => {e.preventDefault(); this.handleChangeMetadata('value', '', i, true);}}>delete</a>
                  </td>
                </tr>
              )}
              <tr><td><a onClick={e => {e.preventDefault(); this.handleChangeMetadata('value', '');}}>+ add option</a></td></tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const metadata = ownProps.parent_type === 'ITEM' ? state.entities.items[ownProps.parent_id].metadata[ownProps.metadata_id] : state.entities.shops[ownProps.parent_id].metadata_list[ownProps.metadata_id];

  return {
    metadata
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onUpdateMetadataList: (metadata_id, field, previous_value) => value => {
    dispatch(updateMetadata(ownProps.parent_id, metadata_id, field, previous_value, value, ownProps.parent_type));
  },
});

const ConnectedMetadataListPopup = connect(mapStateToProps, mapDispatchToProps)(MetadataListPopup);
export default ConnectedMetadataListPopup;
